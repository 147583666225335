// Events and properties are defined in this sheet: https://docs.google.com/spreadsheets/d/1Dzq0J18IhRgJ4Nm03F2-Rjw1RxqxqNv-YwPQrQN8DAE/edit?usp=sharing
export enum AnalyticsEvent {
    LoginAttempt = 'Login - Attempt',
    LoginIncorrectInput = 'Login - Incorrect Input',
    LoginSuccess = 'Login - Success',
    LoginError = 'Login - Error',
    FirstLoginAttempt = 'First Login - Attempt',
    FirstLoginIncorrectInput = 'First Login - Incorrect Input',
    FirstLoginSuccess = 'First Login - Success',
    FirstLoginError = 'First Login - Error',
    GoToResetPassword = 'Go To Reset Password',
    GoToLogin = 'Go To Login',
    RequestVerificationAttempt = 'Request Verification - Attempt',
    RequestVerificationIncorrectInput = 'Request Verification - Incorrect Input',
    RequestVerificationSuccess = 'Request Verification - Success',
    RequestVerificationError = 'Request Verification - Error',
    SetNewPasswordAttempt = 'Set New Password - Attempt',
    SetNewPasswordIncorrectInput = 'Set New Password - Incorrect Input',
    SetNewPasswordSuccess = 'Set New Password  - Success',
    SetNewPasswordError = 'Set New Password  - Error',
    WelcomeDialogShow = 'Welcome Dialog - Show',
    WelcomeDialogClickShowTutorial = 'Welcome Dialog - Click Show Tutorial',
    WelcomeDialogClickSkipTutorial = 'Welcome Dialog - Click Skip Tutorial',
    TutorialFinish = 'Tutorial - Finish',
    TutorialCancel = 'Tutorial - Cancel',
    SettingsClick = 'Settings - Click',
    SaveSettingsAttempt = 'Save Settings - Attempt',
    SaveSettingsError = 'Save Settings - Error',
    SaveSettingsSuccess = 'Save Settings - Success',
    SettingsOpen = 'Settings - Open',
    SettingsClose = 'Settings - Close',
    CreateContactClick = 'Create Contact - Click',
    CreateContactOpen = 'Create Contact - Open',
    CreateContactClose = 'Create Contact - Close',
    CreateContactAttempt = 'Create Contact - Attempt',
    CreateContactIncorrectInput = 'Create Contact - Incorrect Input',
    CreateContactSuccess = 'Create Contact - Success',
    CreateContactError = 'Create Contact - Error',
    EditContactClick = 'Edit Contact - Click',
    EditContactOpen = 'Edit Contact - Open',
    EditContactClose = 'Edit Contact - Close',
    EditContactAttempt = 'Edit Contact - Attempt',
    EditContactIncorrectInput = 'Edit Contact - Incorrect Input',
    EditContactSuccess = 'Edit Contact - Success',
    EditContactError = 'Edit Contact - Error',
    DeleteContactClick = 'Delete Contact - Click',
    DeleteContactConfirm = 'Delete Contact - Confirm',
    DeleteContactCancel = 'Delete Contact - Cancel',
    DeleteContactSuccess = 'Delete Contact - Success',
    DeleteContactError = 'Delete Contact - Error',
    ClickSendEmailToContact = 'Click Send Email To Contact',
    ClickContactAlias = 'Click Contact Alias',
    LoadDataError = 'Load Data Error',
    LoadDatasSuccess = 'Load Data Success',
    GeneralFailure = 'General Failure',
    ShowLoginScreen = 'Show Login Screen',
    ShowContactsScreen = 'Show Contacts Screen',
    MenuOpen = 'Menu - Open',
    MenuClose = 'Menu - Close',
    MenuClickShowTutorial = 'Menu - Click Show Tutorial',
    MenuClickHowToUse = 'Menu - Click How To Use',
    MenuClickLogout = 'Menu - Click Logout',
    NeedHelpShowModal = 'Need Help - Show Modal',
    NeedHelpCloseModal = 'Need Help - Close Modal',
    NeedHelpClickContact = 'Need Help - Click Contact',
    SettingsNeedUpgradeClicked = 'Settings - Need Upgrade Clicked',
    UpgradePackageClick = 'Upgrade Package - Click',
    UpgradePackageOpen = 'Upgrade Package - Open',
    UpgradePackageClose = 'Upgrade Package - Close',
    UpgradeAttempt = 'Stripe Checkout - Open',
    UpgradePackageResult = 'Upgrade Package - Result',
    UpgradePackageError = 'Upgrade Package - Error',
    ManageSubscriptionClick = 'Manage Subscription - Click',
    ManageSubscriptionError = 'Manage Subscription - Error',
}

export enum AnalyticsProperty {
    Reason = 'Reason',
    Error = 'Error',
    Language = 'Language',
    NumberOfContacts = 'Number Of Contacts',
    Topic = 'Topic',
    Result = 'Result',
}

export enum AnalyticsValue {
    EmailInvalid = 'Email Invalid',
    ContactEmailEqualsUserEmail = 'Contact Email Equals User Email',
    NameInvalid = 'Name Invalid',
    VerificatonCodeInvalid = 'Verification Code Invalid',
    PasswordInvalid = 'Password Invalid',
    PasswordsMismatch = 'Passwords mismatch',
    Login = 'Login',
    General = 'General',
}
