import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/browser'
export const initializeAnalytics = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
    })
    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
        const amplitudeClient = amplitude.getInstance()
        amplitudeClient &&
            amplitudeClient.init(process.env.REACT_APP_AMPLITUDE_API_KEY)
    }
}

const getAmplitudeInstance = () => {
    const amplitudeClient = amplitude.getInstance()
    if (!amplitudeClient) {
        throw new Error("Amplitude isn't working :(")
    }
    return amplitudeClient
}

export const trackEvent = (eventName: string, eventProperties?: any) => {
    getAmplitudeInstance().logEvent(eventName, eventProperties)
}

export const trackUser = (
    userId: string,
    userProperties?: { [index: string]: string | number | boolean }
) => {
    const amplitudeInstance = getAmplitudeInstance()
    amplitudeInstance.setUserId(userId)
    if (userProperties) {
        amplitudeInstance.setUserProperties(userProperties)
    }
}
export const stopTrackingUser = () => {
    getAmplitudeInstance().setUserId(null)
}

export * from './constants'
