import React, { useEffect } from 'react'
import FullPageSpinner from './components/FullPageSpinner'
import { ROUTE_LOGIN } from './constants'
import { RedirectTo } from './utils'
import { useAuth } from './context/AuthContext'
import AppProviders from './context'
import { Router } from '@reach/router'

const loadAuthenticatedApp = () => import('./App')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./features/login/'))

const AppWithAuthenticaton: React.FC = () => {
    const { data, getSession } = useAuth()
    useEffect(() => {
        loadAuthenticatedApp()
        getSession()
    }, [])

    return data.isLoading ? (
        <FullPageSpinner />
    ) : (
        <Router>
            {data.isLoggedIn ? (
                <AuthenticatedApp default />
            ) : (
                <>
                    <UnauthenticatedApp path={ROUTE_LOGIN} />
                    <RedirectTo default to={ROUTE_LOGIN} />
                </>
            )}
        </Router>
    )
}

const Main: React.FC = () => (
    <React.Suspense fallback={<FullPageSpinner />}>
        <AppProviders>
            <AppWithAuthenticaton />
        </AppProviders>
    </React.Suspense>
)
export default Main
