import { FlagNameValues } from 'semantic-ui-react'

export const ROUTE_CONTACTS = '/contacts'
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_PAYMENT = '/payment/:type'
export const ROUTE_HELP = '/help'
export const ROUTE_LOGIN = '/login'
export const ROUTE_RESET_PASSWORD = '/login/reset-password'

export const LANGUAGES: Array<{
    key: string
    text: string
    value: string
    flag: FlagNameValues
}> = [
    { key: '1', text: 'English', value: 'EN', flag: 'uk' },
    { key: '2', text: 'Spanish', value: 'ES', flag: 'es' },
    { key: '3', text: 'Chinese', value: 'ZH', flag: 'cn' },
    { key: '4', text: 'German', value: 'DE', flag: 'de' },
    { key: '5', text: 'Japanese', value: 'JA', flag: 'jp' },
    { key: '6', text: 'French', value: 'FR', flag: 'fr' },
    { key: '7', text: 'Portuguese', value: 'PT', flag: 'pt' },
    { key: '8', text: 'Russian', value: 'RU', flag: 'ru' },
    { key: '9', text: 'Italian', value: 'IT', flag: 'it' },
    { key: '10', text: 'Dutch', value: 'NL', flag: 'nl' },
    { key: '11', text: 'Polish', value: 'PL', flag: 'pl' },
]

export const DEFAULT_CONTACT_LANGAUGE = 'ES'
export const DEFAULT_USER_LANGAUGE = 'EN'
export const MAX_INPUT_LENGTH = 100

export const CONTACT_EMAIL = 'info@mailzinga.com'
