import React, { useReducer, createContext, ReactNode } from 'react'
import { ApplicationState } from '../types'
import { delay } from '../utils'

const initialState: ApplicationState = {
    isLoggedIn: true,
    user: {
        id: '',
        name: '',
        email: '',
        alias: '',
        language: '',
        packageId: '',
        packageEmailsLimit: Number.isInteger(
            parseInt(process.env.REACT_APP_FREE_PACKAGE_EMAILS_LIMIT || '')
        )
            ? parseInt(process.env.REACT_APP_FREE_PACKAGE_EMAILS_LIMIT || '')
            : 300,
        packageContactsLimit: Number.isInteger(
            parseInt(process.env.REACT_APP_FREE_PACKAGE_CONTACTS_LIMIT || '')
        )
            ? parseInt(process.env.REACT_APP_FREE_PACKAGE_CONTACTS_LIMIT || '')
            : 5,
        customerId: '',
        sentEmailsThisMonth: 0,
        receivedEmailsThisMonth: 0,
        showCheckoutPageAfterLogin: false,
    },
    contacts: [],
    isMessageShown: false,
    messageContent: '',
    showAddContactModal: false,
    showEditContactModal: false,
    showSettingsModal: false,
    showNeedHelpModal: false,
    isLoading: false,
    editContactId: '',
    showTour: false,
    aPopupIsOpen: false, // used to remove hotjar feedback button when a popup is opened
    isGeneralFailure: false,
    isLoadingFailure: false,
    showCheckoutPageModal: false,
    showSkipModal: true,
}

interface ContextProps {
    state: ApplicationState
    dispatch: (action: Action) => void
    showMessage: (text: string) => void
}

const initContextProps: ContextProps = {
    state: initialState,
    dispatch: () => true,
    showMessage: () => true,
}
const ApplicationContext = createContext(initContextProps)

export enum ActionType {
    LoadData = 'LoadData',
    AddContact = 'AddContact',
    ShowMessage = 'ShowMessage',
    HideMessage = 'HideMessage',
    ShowEditContactModal = 'ShowEditContactModal',
    HideEditContactModal = 'HideEditContactModal',
    ShowAddContactModal = 'ShowAddContactModal',
    HideAddContactModal = 'HideAddContactModal',
    ShowSettingsModal = 'ShowSettingsModal',
    HideSettingsModal = 'HideSettingsModal',
    ShowNeedHelpModal = 'ShowNeedHelpModal',
    HideNeedHelpModal = 'HideNeedHelpModal',
    SetStatusIsLoading = 'SetStatusIsLoading',
    SetContacts = 'SetContacts',
    SetUser = 'SetUser',
    SetUserData = 'SetUserData',
    DeleteContact = 'DeleteContact',
    UpdateContact = 'UpdateContact',
    ShowTour = 'ShowTour',
    HideTour = 'HideTour',
    ResetData = 'ResetData',
    SetAPopupIsOpen = 'SetAPopupIsOpen',
    SetIsLoadingFailure = 'SetIsLoadingFailure',
    SetIsGeneralFailure = 'SetIsGeneralFailure',
    ShowCheckoutPageModal = 'ShowCheckoutPageModal',
    HideCheckoutPageModal = 'HideCheckoutPageModal',
}

export interface Action {
    type: ActionType
    payload?: any
}

export const ApplicationReducer: React.Reducer<ApplicationState, Action> = (
    state: ApplicationState,
    action: Action
): ApplicationState => {
    switch (action.type) {
        case ActionType.LoadData:
            return { ...state }
        case ActionType.AddContact:
            return {
                ...state,
                contacts: [action.payload, ...state.contacts],
                isLoading: false,
            }
        case ActionType.ShowMessage:
            return {
                ...state,
                messageContent: action.payload,
                isMessageShown: true,
            }
        case ActionType.HideMessage:
            return { ...state, isMessageShown: false }
        case ActionType.ShowAddContactModal:
            return { ...state, showAddContactModal: true }
        case ActionType.HideAddContactModal:
            return { ...state, showAddContactModal: false }
        case ActionType.ShowEditContactModal:
            return {
                ...state,
                showEditContactModal: true,
                editContactId: action.payload,
            }
        case ActionType.HideEditContactModal:
            return { ...state, showEditContactModal: false, editContactId: '' }
        case ActionType.ShowSettingsModal:
            return { ...state, showSettingsModal: true }
        case ActionType.HideSettingsModal:
            return { ...state, showSettingsModal: false }
        case ActionType.ShowNeedHelpModal:
            return { ...state, showNeedHelpModal: true }
        case ActionType.HideNeedHelpModal:
            return { ...state, showNeedHelpModal: false }
        case ActionType.SetContacts:
            return { ...state, contacts: action.payload, isLoading: false }
        case ActionType.UpdateContact:
            return {
                ...state,
                contacts: state.contacts.map(item =>
                    item.id === action.payload.id
                        ? { ...item, ...action.payload }
                        : item
                ),
            }
        case ActionType.SetUser:
            return { ...state, user: action.payload, isLoading: false }
        case ActionType.SetUserData:
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload.name,
                    language: action.payload.language,
                },
            }
        case ActionType.SetStatusIsLoading:
            return { ...state, isLoading: action.payload }
        case ActionType.DeleteContact:
            return {
                ...state,
                contacts: state.contacts.filter(
                    contact => contact.id !== action.payload
                ),
                isLoading: false,
            }
        case ActionType.ShowTour:
            return { ...state, showTour: true }
        case ActionType.HideTour:
            return { ...state, showTour: false }
        case ActionType.SetAPopupIsOpen:
            return { ...state, aPopupIsOpen: action.payload }
        case ActionType.SetIsGeneralFailure:
            return { ...state, isGeneralFailure: action.payload }
        case ActionType.SetIsLoadingFailure:
            return { ...state, isLoadingFailure: action.payload }
        case ActionType.ResetData:
            return initialState
        case ActionType.ShowCheckoutPageModal:
            return {
                ...state,
                showCheckoutPageModal: true,
                showSkipModal: action.payload,
            }
        case ActionType.HideCheckoutPageModal:
            return { ...state, showCheckoutPageModal: false }
        default:
            return state
    }
}

const ApplicationContextProvider: React.FC = ({
    children,
}: {
    children?: ReactNode
}) => {
    const [state, dispatch] = useReducer(ApplicationReducer, initialState)
    const showMessage = async (text: string) => {
        dispatch({
            type: ActionType.ShowMessage,
            payload: text,
        })
        await delay(2000)
        dispatch({ type: ActionType.HideMessage })
    }
    return (
        <ApplicationContext.Provider value={{ state, dispatch, showMessage }}>
            {children}
        </ApplicationContext.Provider>
    )
}

const useApplication = () => {
    const context = React.useContext(ApplicationContext)
    if (context === undefined) {
        throw new Error(
            `useApplication must be used within a ApplicationContextProvider`
        )
    }
    return context
}
export { ApplicationContextProvider, useApplication }
