import React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { CONTACT_EMAIL } from '../constants'
import { trackEvent, AnalyticsEvent } from '../analytics'

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const RedirectTo: React.FC<RouteComponentProps & { to: string }> = ({ to }) => (
    <Redirect noThrow to={to} />
)

const focusInputWithPlaceholder = (placeholder: string) => {
    const input: HTMLInputElement | null = document.querySelector(
        `[placeholder="${placeholder}"]`
    )
    input && input.focus()
}

const validateEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

// Password policy: at least 8 chars long, uppercase and lowercase letter, number and a special character

/****************************************   IMPORTANT   ****************************************** */
/*                                                                                                 */
/* Because of a AWS Cognito bug, we have a different password policy on Cognito side.              */
/* Cognito doesn't treat point as a special character, therefore we turned off special characters  */
/* in Cognito password policy                                                                      */
/*                                                                                                 */
/************************************************************************************************* */

const validatePassword = (password: string) => {
    const regex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.*])[\w!@#$%^&.*]{8,}$/
    return regex.test(password)
}

const openPrefilledEmailClient = (email: string, subject: string) => {
    const encodedSubject = encodeURI(subject)
    window.location.href = `mailto:${email}?subject=${encodedSubject}`
}

const needHelpAction = () => {
    const subject = 'I need help using MailZinga'
    trackEvent(AnalyticsEvent.NeedHelpClickContact)
    openPrefilledEmailClient(CONTACT_EMAIL, subject)
}

const needUpgradeAction = () => {
    const subject = 'I need more emails with MailZinga'
    trackEvent(AnalyticsEvent.SettingsNeedUpgradeClicked)
    openPrefilledEmailClient(CONTACT_EMAIL, subject)
}

const setHotjarFeedbackButtonVisibility = (show: boolean) => {
    const hotjarButtonNode = document.querySelector(
        "[id*='_hj_feedback_container']"
    )
    if (hotjarButtonNode !== null) {
        ;(hotjarButtonNode as any).style.display = show ? 'block' : 'none'
    }
}

const isDataConsistent = (data: any) =>
    data.contacts &&
    data.email &&
    data.alias &&
    data.email.trim() &&
    data.alias.trim()

export {
    delay,
    RedirectTo,
    focusInputWithPlaceholder,
    validateEmail,
    validatePassword,
    setHotjarFeedbackButtonVisibility,
    isDataConsistent,
    openPrefilledEmailClient,
    needHelpAction,
    needUpgradeAction,
}
